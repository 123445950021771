function TextRevealTW(props) {
  const text = props.text;

  return (
    <>
      <h1 className="overflow-hidden text-6xl font-bold leading-tight text-green-500">
        {text.match(/./gu)?.map((char, index) => (
          <span
            className="animate-text-reveal inline-block [animation-fill-mode:backwards]"
            key={`${char}-${index}`}
            style={{ animationDelay: `${index * 0.05}s` }}
          >
            {char === " " ? "\u00A0" : char}
          </span>
        ))}
      </h1>
    </>
  );
}

export default TextRevealTW;
